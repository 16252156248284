.bgcolor {
  background-color: #2162a5;
  margin-bottom: 11px;
  border-radius: 4px;
}
.icon-style {
  font-size: 1.7em !important;
  /* margin-top: 1px; */
  float: right;
  color: white;
}
.titlestyle {
  padding-left: 15px;
  color: white;
  font-size: 16px;
}
.link-style {
  /* color: white; */
  /* padding-left: 35px; */
  text-decoration: underline;
}
.totalQualified {
  float: right;
  padding-right: 15px;
}
.green-card {
  border-left: 6px solid #358202 !important;
  width: 196px !important;
  height: 60px;
  /* margin: 0px !important; */
}
.status-card {
  border-left: 6px solid !important;
  color: #666666;
  width: 109px !important;
  height: 30px;
  padding: 0px !important;
  margin: 5px !important;
}
.red-card {
  border-left: 6px solid #b9000a !important;
  width: 196px !important;
  height: 60px;
  /* margin: 0px !important; */
}
.ash-card {
  border-left: 6px solid #666666 !important;
  width: 196px !important;
  height: 60px;
  /* margin: 0px !important; */
}
.green-card .content,
.red-card .content,
.ash-card .content {
  padding: 2px !important;
}
.status-card .content {
  padding: 6px !important;
}
.accordion-padding {
  /* padding-top: 35px; */
  margin-top: 35px;
  background-color: white;
}
.no-data {
  background-color: white;
  margin-top: 100px;
  margin-left: 50%;
}
.card-width {
  width: 88px !important;
}
.green-count {
  color: green !important;
}
.alignment {
  float: right;
  margin-top: 0px !important;
  margin-bottom: 5px !important;
  margin-right: 0px !important;
}

.red-count {
  color: #b9000a !important;
}
a:hover {
  /* color: white !important; */
  text-decoration: underline !important;
}

.container-style {
  max-height: 270px;
  /* overflow: hidden; */
  overflow-y: scroll;
  overflow-x: hidden;
}
.scroll {
  /* overflow-y: hidden ; */
  overflow: hidden;
  overflow-y: scroll;

  height: 150px;
}

/* .scroll:hover {
  overflow-y: scroll;
} */
.scroll::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}

.scrollList {
  overflow: hidden;
  overflow-y: scroll;
  height: 170px;
}

.assignmentScrollList {
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 235px;
}
/* .scrollList:hover {
  overflow-y: scroll;
} */
.assignmentScrollList::-webkit-scrollbar {
  width: 5px;
}
.assignmentScrollList::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}

.scrollList::-webkit-scrollbar {
  width: 5px;
}
.scrollList::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}
/* .container-style:hover{
      overflow-y: scroll;
  } */
.container-style::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
.container-style::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}
.searchDiv {
  float: right;
  /* margin-top: -8px; */
  margin-right: 15px;
}
hr {
  border-color: #ddd;
  opacity: 0.2;
}
.gride {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.font-color {
  color: #666666 !important;
}
.manager-header {
  color: #666666 !important;
  font-size: 16px !important;
}
.manager-header-title {
  margin-left: 15px;
}

.card-font {
  color: #666666 !important;
  font-size: 14px !important;
  padding-top: 5px;
}
.button-style {
  /* padding: 10px !important; */
  color: #2162a5;
  border: 1px solid #2162a5 !important;
}
.icon-color {
  color: #2162a5 !important;
}
.filter-styles {
  padding-right: 0px !important;
  padding-left: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: #f2f6fb !important;
  /* border: 1px solid #CCCCCC !important;
      color: #2162A5 !important;
      width: 29px !important;
      height: 27px !important;
      margin-top: 5px !important; */
}
.filter-margin {
  margin: 0px !important;
}
.close {
  float: right;
}
.export-button {
  padding: 12px !important;
}
.list-style {
  padding-right: 20px !important;
  margin-left: 0px !important;
  width: 195px !important;
  vertical-align: top;
  padding-top: 5px !important;
}
.assignment-list-style {
  padding-right: 10px !important;
  margin-left: 0px !important;
  width: 282px !important;
  vertical-align: top;
  padding-top: 5px !important;
}
.location {
  margin-left: 0px !important;
  padding-top: 5px !important;
}
.ui.basic.button.button-hover {
  border: 1px solid #666666 !important;
  box-shadow: none;
  float: right;
  margin-bottom: 15px;
}
.ui.basic.button.button-hover:hover {
  border: 1px solid #2162a5 !important;
  color: #2162a5 !important;
  box-shadow: none;
}
.content-style {
  margin-left: 15px;
  color: #666666 !important;
}
.selectAll {
  /* margin: 0px !important; */
  position: absolute;
  float: right;
  /* right: 66px; */
  padding-top: 5px;
  padding-right: 10px;
}
.selectallStaff {
  /* left: 65px; */
  float: right;
  margin-top: 0px !important;
}
.ui.horizontal.list.ui.horizontal.list.cardFirstChild > .item:first-child {
  margin-left: 11px !important;
}
.active.title .angle.down.icon {
  transform: rotate(180deg);
}
.ui.horizontal.list > .item.item-width:first-child {
  margin-left: 15px !important;
}
.toppading {
  padding-top: 81px !important;
  padding-bottom: 100px !important;
  /* background-color: white; */
}
.ui.cards.alignment > .ui.basic.button {
  background: white !important;
}
.top {
  padding-top: 65px !important;
  padding-bottom: 30px !important;
}
/* .ui.modal.transition.visible.active {
 
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: -50%;
  transform: translate(-50%, -50%) !important;
} */
/******************************************************************************************/

.textalign {
  text-align: center !important;
  vertical-align: middle !important;
  /* width: 125px; */
}
.table-header > th {
  /* padding: 0px !important; */

  padding-top: 0px !important;
}
.table-cells {
  padding-right: 20px !important;
}
.total {
  background-color: #939393;
  color: white;
}
thead.fixedHeader {
  display: table;
  overflow: auto;
  width: 100%;
}
.scrollContent {
  display: block;
  height: 480px;
  overflow: auto;
  width: 100%;
}
.scrollContent::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Handle */
.scrollContent::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}

.scrolling::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Handle */
.scrolling::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}
.table-head {
  display: inline-block;
  width: 100%;
}
.table-row {
  display: table;
  width: 100%;
  color: #666666;
  align-content: center;
}
.ui.basic.table > .table-head > .table-row > th {
  color: #666666;
}

.ui.table.table-margin .normal .accordion.ui.acccolor .active.title,
.ui.table.table-margin .normal .accordion.ui.acccolor .content.active {
  border-left: 2px solid #2162a5;
}

.table-margin {
  margin-bottom: 30px !important;
}
.color {
  color: #2162a5;
}
.borederTop {
  border-top: 1px solid #dddddd;
}
.backgroundcolor {
  background-color: white;
}
.tableAlign {
  padding-top: 81px !important;
  padding-bottom: 52px !important;
}

.ui.basic.table {
  margin-top: 35px;
}
.red {
  color: #b9000a;
  font-weight: bold;
}

.green {
  color: #358202;
  font-weight: bold;
}

.ash {
  color: #666666;
  font-weight: bold;
}
/****************************************************************/
.listElements {
  width: 100% !important;
  background-color: white !important;
  height: 110px;
}
.managerList {
  width: 100% !important;
  background-color: white !important;
  height: 47px;
}
.m {
  /* margin-top: 100px !important; */
  border: 1px solid lightgray !important;
}
.managerName {
  width: 40%;
}
.names {
  width: 160px;
  padding: 4px !important;
}
.ui.structured.celled.table.m tr th {
  border-right: 0px !important;
}

.ui.table thead tr:first-child > th {
  position: relative !important;
}
.tableCell {
  min-width: 10em;
  padding: 3px !important;
}
.tableAVHeader {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.bordernone {
  border-bottom: none !important;
}
.labelheading {
  font-weight: bold;
  font-size: 15px;
}
.marginleft {
  margin-left: 17px !important;
}
.assignmentmargin {
  margin-left: 5px !important;
}
.assignmentSegment {
  margin-right: 25px !important;
  margin-left: -5px !important;
  padding-top: 3px !important;
}
.filterSegment {
  height: 165px !important;
  margin-bottom: 5px !important;
  padding-top: 3px !important;
}
.ui.container {
  margin-left: 10px !important;
  margin-right: 10px !important;
  width: 99%;
}
.avHeading {
  font-weight: bold;
  padding-top: 3px;
  color: #2162a5;
  text-align: center;
}
.gridClass {
  margin-top: -20px;
}

.ui.modal > .content {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}

.filterSegmentColumn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 10px !important;
  padding-right: 0px !important;
}

.costCenterOpsList {
  padding-right: 2px !important;
  margin-left: 0px !important;
  width: 98% !important;
  vertical-align: top;
  padding-top: 5px !important;
}
.assignmentOpsList {
  padding-right: 2px !important;
  margin-left: 0px !important;
  width: 200px !important;
  vertical-align: top;
  padding-top: 0px !important;
}

.selectAllRow {
  padding-bottom: 0px !important;
}

.assignmentListCheckbox {
  max-width: 92% !important;
}
.ui.modal .scrolling.content {
  overflow: unset !important;
}

.modalAV {
  margin: 1rem auto;
  padding-left: 10px;
  /* height: 57vh; */
  height: 100% !important;
  overflow-y: scroll !important;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ui.modal {
    height: 57% !important;
    overflow-y: scroll;
  }
}

/*Larger screen display - Desktop\PC*/
@media screen and (max-device-height: 1080px) and (min-device-height: 769px) {
  .fixedTable {
    height: 800px !important;
    /* height: 795px !important; */
  }

  .ReactTable .rt-thead.-header {
    top: 0px !important;
  }
}

/*Ipad - Landscape*/
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .fixedTable {
    height: 450px !important;
    /* height: 445px !important; */
  }
}

/*Ipad - Portrait*/
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .fixedTable {
    height: 700px !important;
    /* height: 695px !important; */
  }
}

.statusAlign {
  /* margin-left: 50%; */
  margin: auto;
  width: 17%;
}
.verticalAlign {
  vertical-align: middle !important;
}

.ui.inverted.top.aligned.page.modals.dimmer.transition.visible.active {
  padding-top: 0px !important;
}
.ui.inverted.top.aligned.page.modals.dimmer.transition.visible.active
  .ui.modal.transition.visible.active {
  margin-top: 0px !important;
}

.statusImage {
  float: left;
  padding-right: 3px;
}
.legend {
  padding: 5px;
  margin-top: 2px;
}

.rthfc-th-fixed,
.rthfc-td-fixed {
  /* position: -webkit-sticky; */
  position: sticky !important;
  z-index: 1 !important;
}

.rthfc-th-fixed-left,
.rthfc-td-fixed-left {
  border-right: solid 1px #ccc !important;
  border-bottom: solid 1px #ccc !important;
  border-top: 1px #ccc !important;
  border-left: 1px #ccc !important;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  white-space: normal !important;
  background-color: white;
  border-right: solid 1px #ccc !important;
  border-bottom: solid 1px #ccc !important;
  border-top: 1px #ccc !important;
  border-left: 1px #ccc !important;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ReactTable .rt-td {
  white-space: normal !important;
  background-color: white;
  border-right: solid 1px #ccc !important;
  border-bottom: solid 1px #ccc !important;
  border-top: 1px #ccc !important;
  border-left: 1px #ccc !important;
}

.ReactTable .-header .rt-th,
.ReactTable .-filters .rt-th {
  background-color: white;
}

.ReactTable .rt-tr.-odd .rt-td {
  background-color: white;
}

.ReactTable .rt-tbody .rt-td {
  border: 1px thin lightgrey !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: solid 1px white !important;
  border-bottom: solid 1px white !important;
  border-top: 1px white !important;
  border-left: 1px white !important;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background-color: rgb(217, 217, 217);
}

.ReactTable .rt-thead.-headerGroups {
  border: 1px thin rgb(217, 217, 217);
}

.ReactTable .rt-thead {
  /* position: -webkit-sticky; */
  position: sticky;
  z-index: 2;
}

.fixedTable {
  width: 100%;
  height: 500px;
  /* height: 495px; */
}

.ReactTable .rt-tbody {
  overflow: visible !important;
  -webkit-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}

body ::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.avNameLink {
  color: darkblue;
}

.rthfc-td-fixed-left-last,
.rthfc-th-fixed-left-last {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rt-td,
.rt-th {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ReactTable .-pagination .-pageSizeOptions {
  display: none !important;
}
