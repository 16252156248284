.titlestyle {
  padding-left: 15px;
  color: white;
  font-size: 16px !important;
}
.icon_style {
  font-size: 1.7em !important;
  /* margin-top: 1px; */
  float: right;
  color: white;
}
.bgcolor {
  background-color: #2162a5;
  margin-bottom: 11px;
  border-radius: 4px;
}
.accordion_padding {
  padding-top: 10vh;
}
.li_style {
  padding: 5px !important;
}
.scroll {
  /* overflow-y: hidden ; */
  overflow: hidden;
  height: 210px;
}
.scroll:hover {
  overflow-y: scroll;
}
.scroll::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}

.ui.horizontal.list.ui.horizontal.list.listHorizontal > .item:first-child,
.ui.horizontal.list.ui.horizontal.list.listHorizontal > .item:last-child {
  margin-left: 14px !important;
}
hr {
  border-color: grey !important;
  opacity: 0.2;
}
.content_style {
  margin-left: 15px;
  color: #666666;
}
.active.title .angle.down.icon  {
  transform: rotate(180deg);
}
.buttonDivPaddingTop {
  padding-top: 20px;
}
.submit_cancle {
  float: right;
  box-shadow: none !important;
  border: 1px solid rgba(102, 102, 102, 0.48) !important;
}
.padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.submit_cancle > a {
  color: #666666;
}
.submit_cancle > a:hover {
  color: #2162a5 !important;
  text-decoration: none !important;
}
.submit {
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.list_style {
  word-break: break-word;
}

.modalSAS {
  padding-left: 10px;
  /* height: 57vh; */
  height: 100% !important;
  overflow-y: scroll;
}
