.reporttype .sas {
  margin: 0px !important;
  font-weight: bold;
  font-size: 16px;
  display: inline-block !important;
  padding-left: 10px;
}
.reporttype .lms {
  font-size: 14px;
  font-weight: lighter;
  margin: 0px !important;
  display: inline-block !important;
  padding-left: 10px;
}
.reporttype {
  text-align: left;
  color: #2162a5;
  font-family: Helvetica Neue;
  margin-top: 5px;
  float: left;
  border-left: 1px solid #d8d8d8 !important;
  height: 45px;
  margin-left: 15px;
}
.container_shadow {
  box-shadow: 0px 2px 10px #000000;
  background: white;
  position: fixed;
  top: 1px;
  z-index: 999;
}
.container_shadow,
.new {
  width: 100%;
}
.headerTextAlign {
  text-align: right;
}
.amgen_logo {
  float: left;
  margin-left: 45px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.margin {
  margin-top: 10px;
}
nav a {
  color: #2162a5;
  text-decoration: none;
  padding: 6px 10px !important;
}
.logout {
  font-family: Helvetica Neue, Medium;
  font-size: 1vw;
  color: #2162a5;
  vertical-align: -webkit-baseline-middle;
}
.user_name {
  font-family: Helvetica Neue, Medium;
  font-size: 1vw;
  color: #666666;
  vertical-align: -webkit-baseline-middle;
}
.margin {
  margin-top: 10px;
}
