.text {
  font-family: Helvetica Neue;
  font-size: 1.3vw;
  color: #2162a5;
}
.image_1280px_amgen {
  position: absolute;
  margin-top: 90px;
  margin-left: 32px;
}
.img_undraw_site_status {
  position: absolute;
  margin-left: -515px;
  z-index: -1;
}
.sas_text {
  margin-bottom: 100px;
  text-align: center;
}
.sas_text a:hover {
  color: #2162a5 !important;
  text-decoration: none !important;
}
.sas_text a:focus {
  text-decoration: none !important;
}
.sas_text_insideimage {
  position: absolute;
  font-size: 1vw;
  color: #2162a5;
  font-family: Roboto;
  margin-top: 85px;
  margin-left: -95px;
}
.col_items {
  margin-top: 125px;
}

.instructions {
  margin-left: -30px;
  font-family: Helvetica Neue;
  color: #666666;
}
.instructions p {
  margin-left: 24px;
  font-size: 1.5vw;
  font-weight: lighter;
}

.instructions ul li {
  font-size: 1vw;
}

.tasIcon {
  width: 30vw;
  float: left;
  margin-left: 5vw;
}
.sasIcon {
  margin-left: 10vw;
  float: center;
}
.dashBoard {
  width: 80vw;
  padding-top: 10vh;
}
.tasIconText {
  font-size: 11px;
  text-align: justify;
  margin-left: 2vw;
}
.sasIconText {
  font-size: 11px;
  /* text-align: justify;
  width: 25vw;
  padding-left: 3vw; */
    
}
