.container {
  width: 100%;
  margin: 0 auto;
}
.container_margin_top {
  margin-top: 58px;
}
.lms_text {
  font-size: 2vw;
  margin-top: 10px;
  color: #707070;
  margin-left: -56px;
  font-family: Helvetica Neue, Light;
}
