.new {
  margin-top: 0%;
  background-color: #0157a1;
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.rights_reserved {
  text-align: center;
  margin-bottom: 22px !important;
  padding-top: 10px;
  font-size: 0.9vw;
  color: #9eb0f3;
  font-family: Helvetica Neue;
}
.footer {
  margin-top: 0px !important;
  background-color: #0062c2 !important;
  border-radius: unset !important;
  bottom: 0 !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 999;
}
.allrightsText {
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  font-size: 12px;
}
