.search.icon {
  color: blue;
}

/*User prompt - start*/
.gridMargin {
  margin-left: 5px !important;
  background-color: white;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;
  display: inline-block !important;
  width:100%
}
.opsList {
  padding-right: 2px !important;
  margin-left: 0px !important;
  width: 208px !important;
  vertical-align: top;
  padding-top: 5px !important;
}
.avItemList {
  padding-right: 2px !important;
  margin-left: 0px !important;
  width: 33% !important;
  vertical-align: top;
  padding-top: 5px !important;
}
.rightAlign {
  /* left: 815px; */
  float: right;
}
.hrwidth {
  width: 95%;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.left {
  margin-left: 540px !important;
}

.submit-cancle {
  float: right;
  box-shadow: none !important;
  border: 1px solid rgba (102,  102,  102,  0.48) !important;
  margin-bottom: 20px !important;
}
.loader {
  padding: 10px;
}
.paddingtop {
  padding-top: 50px !important;
  padding-bottom: 40px !important;
}

.submit-cancle {
  float: right;
  box-shadow: none !important;
  border: 1px solid rgba(102, 102, 102, 0.48) !important;
  font-size: 14px !important;
  color: rgba(102, 102, 102, 1);
}

.ui.basic.button.submit-cancle:hover {
  color: #2162a5 !important;
  border-color: #2162a5 !important;
  background: #f2f6fb !important;
}

.submit-cancle > a {
  color: #666666;
}
.submit-cancle > a:hover {
  color: #2162a5 !important;
  text-decoration: none !important;
}
.submit {
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: #2162a5 !important;
}
.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: white !important;
  background-color: #2162a5 !important;
}
.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  border-color: #2162a5 !important;
}

.listHeight {
  overflow-y: auto;
  max-height: 188px;
}

/* .listHeight::-webkit-scrollbar-thumb  {
  background: rgba(33, 98, 165, 1) !important;
} */

.ui.search > .results {
  height: 200px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

body ::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}
body ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.top {
  padding-top: 65px !important;
  padding-bottom: 30px !important;
}
/*User prompt - end*/
.buttonmargin {
  margin-right: 10% !important;
}

.instruction {
  vertical-align: middle;
  font-size: 12px;
  text-align: center;
}
.instructionLA {
  padding-left: 12%;
}
.ui.basic.table {
  margin-top: 5px;
}
.statusHomePage {
  display: inline-block !important;
}
