.filter-styles {
  padding: 0px !important;
  background-color: #f2f6fb !important;
}
.middleDiv {
  height: 200px;
  width: 400px;

  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
}

.float-left {
  float: left;
}

.close {
  float: right;
}

.ui.basic.button.button-hover {
  border: 1px solid #666666 !important;
  box-shadow: none;
  float: right;
  margin-bottom: 15px;
}
.ui.basic.button.button-hover:hover {
  border: 1px solid #2162a5 !important;
  color: #2162a5 !important;
  box-shadow: none;
}

.tab-style > .ui.tabular.menu > .active.item {
  color: #2162a5 !important;
  border-top: 3px solid;
}
.tab-style > .ui.tabular.menu > .item {
  font-weight: bold !important;
  color: #2162a5 !important;
}
.tab-style > .ui.tabular.menu > a:hover {
  color: #2162a5 !important;
  text-decoration: none !important;
}
.textalign {
  text-align: center !important;
  width: 125px;
}
.table-header > th {
  /* padding: 0px !important; */

  padding-top: 0px !important;
}
.table-cells {
  padding-right: 20px !important;
}
.total {
  background-color: #939393;
  color: white;
}
thead.fixedHeader {
  display: table;
  overflow: auto;
  width: 97vw;
}
tbody.scrollContent {
  display: block;
  height: 320px;
  overflow: auto;
  width: 100%;
}
.scrollContent::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.scrollContent::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}

/* Handle */

.scroll::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}
.table-head {
  display: inline-block;
  width: 100%;
}
.table-row {
  display: table;
  width: 100%;
  color: #666666;
  align-content: center;
}
.ui.basic.table > .table-head > .table-row > th {
  color: #666666;
}
.completed {
  width: 115px;
}
.incomplete {
  width: 115px;
}
.completePercent {
  width: 115px;
}

/* 
.Not-esigned-cell{
  width: 150px;
}
*/
.completePercent-cell {
  width: 110px;
}
.completed-cell {
  width: 113px;
}
.table-footer {
  background-color: #939393;
  color: white !important;
  font-weight: bold;
}
.tf-lastChild {
  padding-right: 13px !important;
}
.align {
  float: right;
  padding-left: 5px !important;
  margin-top: -5px !important;
}
.search-div {
  position: absolute;
  right: 25px;
  top: 85px;
}
#page-numbers {
  list-style: none;
  display: flex;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: #2162a5;
  user-select: none;
  cursor: pointer;
}
.heading {
  width: 129px;
  color: #666666;
  font-weight: bold;
}
.item-width {
  width: 125px;
  vertical-align: -webkit-baseline-middle;
  color: #666666;
}
.ui.horizontal.list.listfirstchild > .item:first-child {
  margin-left: 14px !important;
}
.acc_icon_styles {
  float: right;
}
.innerList {
  padding-left: 22px !important;
  color: #666666;
}
.highlight > .acccolor {
  background: #dddddd; /* green */
}
.normal > .acccolor {
  background-color: white;
}
.active.title .triangle.down.icon {
  transform: rotate(180deg) !important;
}
.ui.table.table-margin .normal .accordion.ui.acccolor .active.title,
.ui.table.table-margin .normal .accordion.ui.acccolor .content.active {
  border-left: 2px solid #2162a5;
}
.title .triangle.down.icon {
  /* transform: rotate(180deg) !important; */
  float: right;
  font-size: 20px !important;
  margin-right: 10px;
  margin-top: 14px;
  color: #666666;
}
.pagination-controls__button--active {
  background-color: #4d7ea8;
  color: white;
}
.pageNumbers {
  border: 1px solid #2162a5;
  border-radius: 4px;
  float: right;
  margin-top: -23px !important;
}
.ui.ui.horizontal.list.pageNumbers .pagination-controls__button {
  width: 26px;
  padding: 7px !important;
  margin-left: 0px !important;
  cursor: pointer;
  border-right: 1px solid #2162a5;
}
.ui.ui.horizontal.list.pageNumbers .pagination-controls__button:last-child {
  border-right: none;
}
.table-margin {
  margin-bottom: 30px !important;
}
.color {
  color: #2162a5;
}
.borederTop {
  border-top: 1px solid #dddddd;
}
.backgroundcolor {
  background-color: white;
}
.tableAlign {
  padding-top: 81px !important;
  padding-bottom: 52px !important;
}
.opsList {
  padding-right: 20px !important;
  margin-left: 0px !important;
  width: 230px !important;
  vertical-align: top;
  padding-top: 5px !important;
}
.scroll {
  /* overflow-y: hidden ; */
  overflow: hidden;
  height: 150px;
}

.scroll:hover {
  overflow-y: scroll;
}

div.scroll-report .ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

/*
 .ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
} 

 #detailed-report thead {
  width: calc( 100% - 1.1em );
}

#detailed-report thead,tbody tr {
  display: table;
  width: 97vw;
  table-layout: fixed;
} */

/* .header {
  position: sticky !important;
  z-index: 2;
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
} */

/* #detailed-report tbody {
  display: block;
  max-height: 200px;
  overflow: scroll;
  width:97vw
} */

.scroll-report {
  height: 65vh;
  overflow: auto;
}

.scroll-report::-webkit-scrollbar-thumb {
  background: #2162a5 !important;
}
.scroll-report::-webkit-scrollbar {
  width: 6px;
}
.scroll-report::-webkit-scrollbar {
  height: 6px;
}
