.learningActivity_scroll{
    height: 219px;
    overflow: hidden;
}
.learningActivity_scroll:hover{
    overflow-y: scroll;
}
.learningActivity_scroll::-webkit-scrollbar {
    width: 5px !important;
  }
.learningActivity_scroll::-webkit-scrollbar-thumb {
    background: #2162A5 ; 
  }