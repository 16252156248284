.list_style {
  padding-right: 20px !important;
  margin-left: 0px !important;
  width: 200px !important;
  vertical-align: top;
  padding-top: 5px !important;
 word-break: break-all
}
.li_style {
  padding: 5px !important;
}
.sas_assignment_list_style {
  padding-right: 10px !important;
  margin-left: 0px !important;
  width: 360px !important;
  vertical-align: top;
  padding-top: 5px !important;
}
